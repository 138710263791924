html {
  box-sizing: border-box;
  overflow-x: hidden;
  width: 100%;
  --section-primary-background: #0D7D3F;
  --section-primary-text: #ffffff;
  --section-secondary-background: #ffffff;
  --section-secondary-text: #012302;
  --section-tertiary-background: #ffffff;
  --section-tertiary-text: #012302;
  --section-quarternary-background: #ffffff;
  --section-quarternary-text: #012302;
  --primary-color: #0D7D3F;
  --button-color: #0D7D3F;
  --hero-two-column-color: #0D7D3F;
  --hero-one-column-color: #0D7D3F;
  --section-upcoming-background: #034828;
  --hero-text-color: #010101;
  --secondary-color: #034828;
  --tertiary-color: #049546;
  --quarternary-color: #252527;
  --quinary-color:#142616;
  --senary-color: #72BE44;
  --septenary-color:#006D47;
  --octonary-color: #F6FBF2;
  --nonary-color: #142615;
  --white: #ffffff;
  --black: #000000;
  --red: red;
  --font-heading: "Easley";
  --font-body: "Moderat";
  --font-body-two: "PP Grafier";
}

@font-face {
  font-family: 'Easley';
  src: url(./assets/Easley-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Moderat';
  src: url(./assets/Moderat-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'PP Grafier';
  src: url(./assets/PP-Grafier.woff2) format('woff2');
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* .dark .btn{
  color: var(--quarternary-color) !important;
}
.dark .btn:hover{
  color: var(--white) !important;
} */

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-body);
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--quarternary-color);
  background: var(--white);
}

h1,h2,h3,h4,h5{
  margin: 0;
  font-weight: 500;
  line-height: 1.1;
  font-family: var(--font-heading);
}
h1 strong, h2 strong, h3 strong, h4 strong, h5 strong{
  font-weight: 500;
}
.text-right{
  text-align: right;
}
.text-left *{
  text-align: left;
}
.text-left{
  align-items: flex-start;
}
h1{
  font-size: 50px;
}
h2{
  font-size: 35px;
}
h3{
  font-size: 25px;
}
h4{
  font-size: 16px;
}
@media (min-width:768px) {
  h1{
    font-size: 65px;
  }
  h2{
    font-size: 55px;
  }
  h3{
    font-size: 35px;
  }
  h4{
    font-size: 18px;
  }
}

.front-end-hidden{
  visibility: hidden;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.moderat-title{
  font-family: var(--font-body)
}
.pp-grafier{
  font-family: var(--font-body-two);
}

p{
  margin: 0;
}

img{
  width: 100%;
  height: auto;
  display: block;
}

a {
  color: inherit;
  text-decoration: underline;
}

a:active,
a:hover {
  color: inherit;
}

ul, ol{
  white-space: normal;
}

.btn, .gravity-form button[type="submit"]{
  border: 2px solid var(--button-color);
  background-color: var(--button-color);
  color: white !important;
  text-transform: uppercase;
  min-width: 168px;
  padding: 16px 38px;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  letter-spacing: 1px;
  border-radius: 32px;
  font-size: 16px;
  line-height: 1.2;
  outline: 0;
  height: auto;
  transition: all ease .3s;
}

.btn:hover, .gravity-form button[type="submit"]:hover{
  background: var(--nonary-color);
  border: 2px solid var(--nonary-color);
  color: white;
}
@media (max-width:768px) {
  .btn, .gravity-form button[type="submit"]{
    min-width: min-content;
    padding: 14px 17px;
    font-size: 14px;
  }
}

.page-content{
  white-space: pre-wrap;
  overflow-x: hidden;
}
.grid-wrapper{
  display: flex;
  flex-direction: column;
}
.grid-wrapper.grid-wrapper--reverse{
  flex-direction: column;
}
.grid{
  display: flex;
}
@media (min-width:768px) {
  /* .page-content > .section .animated-block > div{
    padding-top: 120px;
  }
  .page-content > .section .animated-block > .hero.one_column{
    padding-top: 120px;
  }
  .page-content > .section .animated-block > .hero.two_column{
    padding-top: 90px;
  }
  .page-content > .section .animated-block > .search-block{
    padding-top: 150px;
  } */
}
.grid.lap-one-half{
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}
.grid.lap-one-third{
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}
.grid.lap-one-quarter{
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
}
.buttons-wrapper{
  gap: 10px;
  display: flex;
}

@media (min-width:1025px) {
  .grid-wrapper.grid-wrapper--reverse{
    flex-direction: row-reverse;
  }
  .grid-wrapper{
    flex-direction: row;
  }
  .grid.lap-one-half{
    flex-basis: 50%;
  }
  .grid.lap-one-third{
    flex-basis: 33.33%;
  }
  .grid.lap-one-quarter{
    flex-basis: 25%;
  }
}

.application, .application_0, .application_1, .application_2{
  margin-top: 90px;
}
header{
  background: #F6FBF2;
  position: fixed;
  top: 0;
  z-index: 11;
  width: 100%;
  height: 90px;
  padding: 20px 30px;
  display: flex;
  align-items: center;
}
header .btn{
  border: 2px solid #0D7D3F !important;
  background-color: #0D7D3F !important;
  color: #fff;
  font-size: 15px;
}
header .btn:hover{
  border: 2px solid #034828 !important;
  background-color: #034828 !important;
}
header .menu-items{
  list-style: none;
  display: flex;
}
header .menu-items .active a{
  color: #009448;
}
header .menu-items .active::before{
  content: ' ';
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: -6px;
  background: #00953C;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}
.menu-items li{
  padding: 10px 20px;
  position: relative;
}
.menu-items li a{
  color: #142615;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  font-family: var(--font-heading);
  font-size: 15px;
}
.menu-items li a:hover{
  color: #009448;
}
header .utility{
  position: absolute;
  right: 52px;
  top: 50%; 
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 23px;
}
header .utility__icon{
  appearance: none;
  border: 0;
  background: 0;
  position: absolute;
  top: 8px;
  right: 0;
}
.utility__icon:hover{
  cursor: pointer;
}
.utility__icon:hover svg path{
  fill: var(--nonary-color)
}
header .header-logo-container{
  position: absolute;
  left: 0;
  top: 50%; 
  transform: translateY(-50%);
}

@media (min-width:1250px) {
  header{
    padding: 20px 100px;
  }
  .hero.two_column .grid-wrapper{
    position: relative;
    overflow: hidden;
  }
  .hero.two_column .hero--images-container img{
    object-fit: cover;
  }
  .hero.two_column .hero--images-container{
    width: 100%;
    min-height: 700px;
  }
  .hero.max-height-set.two_column .grid-wrapper{
    min-height: unset
  }
  .hero.max-height-set.two_column .hero--images-container{
    min-height: unset
  }
  .hero.two_column .hero--images-container *{
    height: 100%;
  }
}
@media (min-width:1480px) {
  .hero.two_column .grid-wrapper, .hero.two_column .hero--images-container{
    min-height: 47vw;
  }
}
.header-outer{
  /* max-width: 1440px; */
  width: 100%;
  margin: 0 auto;
}
.header-inner{
  padding: 20px 160px;
  box-sizing: border-box;
  position: relative;
}
.hero{
  position: relative;
}
.hero .buttons-wrapper{
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
}
.hero .content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align:center;
}
.hero .content .background-art{
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.hero .content .background-art{
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}
.hero .content .background-art img{
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.hero .anchor-image-middle{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  bottom: 0;
  top: 0;
  min-height: 700px;
  height: 100%;
  z-index: 5;
}
.hero .anchor-image-bottom{
  position: absolute;
  top: calc(100% - 5vw);
  height: auto;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 5;
}
.hero .content h1{
  padding: 0px 10px;
  max-width: 625px;
  margin-bottom: 20px;
}
.hero .btn{
  background: transparent;
  border: 2px solid #7BBF44 !important;
}
.hero.dark .btn{
  background: transparent !important;
  border: 2px solid #7BBF44 !important;
  color: #034828 !important;
}
.hero.one_column{
  background: var(--hero-one-column-color);
  color: var(--hero-text-color);
  padding: 35px 20px;
}
.hero.two_column{
  background: var(--hero-two-column-color);
  color: white;
}
.hero.one_column .content{
  padding: 40px 0px 30px;
  z-index: 10;
  position: relative;
}
.hero.dark .content{
  color: var(--quarternary-color);
}
.hero.one_column .content h1{
  padding: 0px 0px;
}
.hero.two_column h3.hero-header{
  padding: 0px 50px;
  max-width: 500px;
}
.hero.two_column h2.hero-header{
  padding: 0px 40px;
  max-width: 560px;
}
.hero.one_column .hero-header{
  max-width: 1200px;
}
.hero .hero-body{
  color: white;
  font-size: 26px;
}
.hero .grid-wrapper .grid{
  position: relative;
}
.hero.one_column .anchor-image-bottom{
  left: 50%;
  right: 0;
  width: 200%;
  transform: translateX(-50%);
}
.hero .content .hero__logo{
  margin-bottom: 50px;
}
.hero__logo + .buttons-wrapper:empty{
  margin-top: -30px;
}
@media (min-width: 768px) {
  .hero.one_column{
    padding: 60px 80px 100px;
  }
  .hero.one_column .content h1{
    padding: 0px 40px;
  }
  .hero.one_column .anchor-image-bottom{
    left: 0;
    width: 100%;
    transform: none;
  }
  .hero .hero-body{
    color: white;
    font-size: 35px;
  }
  
}
@media (min-width: 1250px) {
  .hero.two_column .content{
    padding: 100px 0px;
  }
}
@media (max-width: 1250px) {
  .hero .grid-wrapper{
    max-height: none !important;
  }
  .hero .anchor-image-middle{
    display: none;
  }
  .hero__logo{
    max-width: 250px !important;
  }
  .hero .content .hero__logo{
    margin-bottom: 30px;
  }
  .hero--images-container, .hero--images-container *{
    width: 100%;
  }
  .hero--images-container img{
    height: 68vw;
    width: 100%;
  }
  .hero .grid-wrapper{
    flex-direction: column;
  }
  .hero .content{
    padding: 40px 20px;
  }
  .hero .content h1{
    font-size: 45px;
  }
  .grid.lap-one-half{
    flex-basis: 100;
  }
}

.primary{
  background: var(--section-primary-background);
  color: var(--section-primary-text);
}
.primary *{
  color: var(--section-primary-text);
}
.primary .slim-header-and-content-block__header{
  color: var(--section-primary-text) !important;
}
.primary h1 strong{
  color: var(--tertiary-color);
}
.secondary{
  background: var(--section-secondary-background);
  color: var(--section-secondary-text);
}
/* .secondary *{
  color: var(--section-secondary-text);
} */
.tertiary{
  background: var(--section-tertiary-background);
  color: var(--section-tertiary-text);
}

.quaternary{
  background: var(--section-quarternary-background);
}

footer{
  background: #F6FBF2;
  border-top: 2px solid #00953c;
}
footer .inner{
  max-width: 1700px;
  margin: 0 auto;
  padding: 50px;
}
footer .grid-wrapper .grid.text-left{
  align-items: flex-start;
}
footer .grid-wrapper .grid.text-right{
  align-items: flex-end;
}
footer .grid-wrapper .grid{ 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
footer .grid-wrapper .grid.logo{
  gap: 23px;
}

footer ul, footer .social-links{
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

footer ul li{
  padding: 0 20px 0 0;
}
footer ul.sub-pages{
  margin-bottom: 20px;
}
footer ul.sub-pages li a{
  text-decoration: none;
  font-size: 18px;
  font-family: var(--font-heading);
}
footer ul.sub-pages li a:hover{
  color: var(--primary-color);
}
footer ul.legal-pages li a{
  text-decoration: none;
}
footer ul.legal-pages li a:hover{
  color: var(--quarternary-color);
}
footer .social-links{
  margin-top: 20px;
}
footer .social-links a{
  padding: 0px 5px;
  text-decoration: none;
}
.footer-legal-bar{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background: #0D7D3F;
  padding: 17px;
  color: white;
}
footer .social-links a:hover svg path{
  fill: white;
}
.footer_logos{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

@media (max-width: 1250px) {
  footer .grid-wrapper .grid.text-left{
    order: 2;
    align-items: center;
    margin-bottom: 40px;
  }
  footer .grid-wrapper .grid.text-right{
    order: 3;
    align-items: center
  }
  footer .grid-wrapper .grid.logo{ 
    order: 1;
    align-items: center;
    margin-bottom: 40px;
  }
  .footer-legal-bar{
    flex-direction: column;
  }
  footer ul li{
    padding: 0 10px 0 10px;
  }
}


.link-wrapper{
  text-align: center;
  margin-bottom: 20px;
}
.link-wrapper a{
  display: block;
  text-decoration: none;
}
.link-wrapper a span{
  display: block;
  text-align: center;
}
.link-wrapper a:hover{
  color: var(--primary-color);
}
.media-items{
width: 100%;
}
.media-items__image img{
  display:block;
}

.subpages{
  color: #01462B;
}
@media (min-width: 768px) {
  footer .inner{
    padding: 40px 100px;
  }
  .link-wrapper a span{
    display: block;
    text-align: left;
  }
}

/* Featured Events */

.featured-events-block{
  padding: 50px 30px;
  margin: 0 auto;
  max-width: 1740px;
}

.featured-events-block .inner{

}
.featured-events-block .inner .grid-wrapper{
  gap: 35px;
}

.featured-events-block__header{
  text-align: center;
  color: #012302;
  margin-bottom: 45px;
}
.featured-events-block__header strong{
  color: #049546;
}

.featured-event-item{
  text-decoration: none;
}

.featured-event-item:hover{

}

.featured-events-block .featured-event-item-content{
  padding: 30px 20px;
  line-height: 32px;
  position: relative;
}

.featured-events-block .featured-event-item__title{
  font-family: var(--font-body);
  margin-bottom: 25px;
  padding-right: 50px;
}

.featured-events-block .featured-event-item__body{
  margin-bottom: 10px;
}
.featured-events-block .svg-wrapper{
  position: absolute;
  top: 30px;
  right: 43px;
}

.event-detail-action-secondary{
  font-size: 16px;
  color: var(--primary-color);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.event-detail-action-secondary span{
  position: relative;
  top: -1px;
}

.featured-event-item__img{
  overflow: hidden;
  position: relative;
  width: 100%;
  background: #c6c6c6;
}
.featured-event-item{
  width: 100%;
}

@media (min-width: 768px) {
  .featured-events-block{
    padding: 50px 80px;
  }  
  .featured-events-block .featured-event-item-content{
    padding: 35px 50px;
  }
  .featured-event-item__img{
    height: 73vw;
  }
  .featured-event-item__img img{
    position: absolute;
    width: 101%;
    height: 101%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
    object-fit: cover;
  }
  .featured-event-item:hover .featured-event-item__img img{
    width: 105%;
    height: 105%;
  }
}
@media (min-width: 1024px) {
  .featured-event-item__img{
    height: 40vw;
  }
}
@media (min-width: 1250px) {
  .featured-events-block{
    padding: 100px 160px;
  }
}
@media (min-width: 1780px) {
  .featured-event-item__img{
    height: 660px;
    position: relative;
  }
}

/* Upcoming Events */
.upcoming-events-block{
  padding: 50px 20px;
  margin: 0 auto;
  max-width: 1740px;
}
.upcoming-events-block .header{
  margin-bottom: 56px;
  padding: 0px 30px;
  text-align: center;
}
.upcoming-events-block-inner{
  display: flex;
  flex-direction: column;
}
.upcoming-events-block .anchor-image-top{
  position: absolute;
  bottom: calc(100% - 10px);
  left: 0;
}
.upcoming-events-primary{
  width: 100%;
}
.upcoming-events-secondary{
  width: 100%;
  padding: 30px 0px 30px 0px;
}
.upcoming-events-secondary__title{
  max-width: 500px;
  margin-bottom: 50px;
}
.upcoming-events-primary-details{
  background: var(--section-upcoming-background);
  color: white;
  padding: 40px 25px 30px;
  position: relative;
}
.date-box{
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 12px;
  font-size: 24px;
  border: 2px solid var(--senary-color);
  width: 100px;
  height: 100px;
  text-align: center;
}
.upcoming-events-secondary-details{
  padding: 0px 20px;
}
.upcoming-events-primary-details__title{
  font-size: 24px;
  margin-bottom: 20px;
}
.upcoming-events-primary-details .btn{
  position: relative;
  right: 0;
  bottom: 0;
  margin-top: 25px;
  display: inline-block;
  margin-bottom: 0;
}
.event-list-box{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid var(--nonary-color);
  margin-bottom: 30px;
}
.event-list-box__title{
  margin-bottom: 20px;
}
.event-list-box p{
  margin-bottom: 20px;
}
.event-list-box a{
  text-transform: uppercase;
  font-size: 16px;
  color: var(--primary-color);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
  margin-bottom: 30px;
}
.event-list-box:last-child{
  border-bottom: 0px;
}
.upcoming-events-secondary .button-wrapper{
  margin-top: 34px;
}

@media (max-width: 768px) {
  .upcoming-events-secondary-details{
    padding: 0px 35px;
  }
  .upcoming-events-primary-details .btn{
    position: relative;
    right: 0;
    bottom: 0;
    margin-top: 25px;
    display: inline-block;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .upcoming-events-secondary{
    padding: 30px 30px 30px 30px;
  }
  .upcoming-events-primary-details__title{
    margin-bottom: 30px;
    max-width: none;
  }
  .upcoming-events-primary-details{
    padding: 40px 50px 45px;
  }
  .upcoming-events-primary-details .p{
    max-width: none;
  }
  .upcoming-events-primary-details .btn{
    margin-top: 36px;
  }
}
@media (min-width: 1250px) {
  .upcoming-events-block-inner{
    flex-direction: row;
  }
  .upcoming-events-primary{
    width: 50%;
  }
  .upcoming-events-block{
    padding: 80px 80px;
  }
  .upcoming-events-secondary{
    width: 50%;
    padding: 30px 60px 30px 80px;
  }
}
@media (min-width: 1400px) {
  .upcoming-events-block{
    padding: 120px 160px 120px;
  }
  .upcoming-events-secondary{
    padding: 30px 60px 30px 120px;
  }
}

@media (min-width: 1560px) {
  .upcoming-events-primary-details .btn{
    position: absolute;
    right: 50px;
    bottom: 70px;
  }
  .upcoming-events-primary-details{
    padding: 40px 50px 70px;
  }
  .upcoming-events-primary-details__title{
    margin-bottom: 30px;
    max-width: 287px;
  }
  .upcoming-events-primary-details .p{
    max-width: 287px;
  }
}


.slim-header-and-content-block{
  padding: 50px 30px;
  position: relative;
}
.slim-header-and-content-block-inner{
  max-width: 1096px;
  margin: 0 auto;
  display: flex;
  /* justify-content: flex-end; */
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 25px;
  color: #01462B;
  z-index: 1;
  position: relative;
}
.slim-header-and-content-block-inner.empty-content{
  gap: 0;
}
.slim-header-and-content-block-heading, .slim-header-and-content-block-settings{
  flex-basis: 50%;
}
.slim-header-and-content-block-settings{
  margin-top: 0px;
  line-height: 32px;
}
.slim-header-and-content-block__header{
  color: #012302;
}
.slim-header-and-content-block__header strong{
  color: #00953C;
}
.primary .slim-header-and-content-block__header{
  color: #ffffff;
}
.primary .slim-header-and-content-block__header strong{
  color: #72BE44;
}
.slim-header-and-content-block__body_large{
  font-size: 26px;
  line-height: 1.2;
}
.slim-header-and-content-block__body_large + .slim-header-and-content-block__body_small{
  margin-top: 30px;
}
.slim-header-and-content-block-settings .buttons-wrapper{
  margin-top: 20px;
}
.slim-header-and-content-block-image-left{
  position: absolute;
  left: 0;
  top:0;
  width: 60px;
  height: 100%;
}
.slim-header-and-content-block-image-left img{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: contain;
  object-position: top left;
}
.slim-header-and-content-block-image-right{
  position: absolute;
  right: 0;
  top:0;
  width: 60px;
  height: 100%;
}
.slim-header-and-content-block-image-right img{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: contain;
  object-position: top right;
}

@media (min-width: 768px) {
  .slim-header-and-content-block__body_large{
    font-size: 35px;
  }
  .slim-header-and-content-block-inner{
    flex-direction: row;
    gap: 81px;
  }
  .slim-header-and-content-block-settings{
    margin-top: 50px;
  }
  .slim-header-and-content-block{
    padding: 80px 50px;
    position: relative;
  }
  .slim-header-and-content-block-image-left{
    width: 245px;
  }
  .slim-header-and-content-block-image-right{
    width: 245px;
  }
}
@media (min-width: 1250px) {
  .slim-header-and-content-block{
    padding: 100px 160px 80px;
  }
  .slim-header-and-content-block-inner{
    gap: 81px;
  }
}
@media (min-width: 1400px) {

}

/* Calendar */

.calendar-block .tooling{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 30px;
}
.category-filter{
  display: flex;
  height: 51px;
  width: 100%;
}
.category-filter .keys{
  display: flex;
  list-style: none;
  gap: 1px 18px;
  color: #006D47;
  flex-wrap: wrap;
  margin: 0;
  justify-content: flex-start;
  align-items: center;
  max-width: 500px;
}
.category-filter .keys .color{
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 7px;
}
.category-filter .keys li{
  display: flex;
  gap: 13px;
}

.category-filter select{
  appearance: none;
  border: 0;
  font-size: 15px;
  font-family: var(--font-body);
  text-transform: uppercase;
}
.category-filter-inner{
  padding: 12px 52px 11px 20px;
  border-radius: 30px;
  border: 2px solid #82C342;
  position: relative;
  width: 100%;
  z-index: 10;
  cursor: pointer;
}
.category-list{
  background: #72be45;
  width: 91%;
  left: 50%;
  display: flow;
  position: absolute;
  top: calc(100% + 5px);
  border-radius: 11px;
  left: 50%;
  transform: translateX(-50%);
  padding: 19px 14px;
  margin: 0;
  z-index: -1;
  list-style: none;
}
.category-list li{
  font-weight: bold;
  padding: 6px;
  cursor: pointer;
}

.category-filter svg{
  position: absolute;
  top: -5px;
  right: -2px;
  width: 49px;
}

.calendar-block{
  padding: 50px 30px 30px;
  max-width: 1780px;
  margin: 0 auto;
}
.calendar-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0;
  width: calc(100% - 380px);
  margin: auto;
  margin: 0;
}

.calendar-container .day {
  min-height: 8vw;
  min-height: 100px; /* Adjust based on your preference */
  border-bottom: 1px solid #049546; /* Adds a border around each day */
  display: flex;
  align-items: flex-start; /* Vertically centers the content */
  justify-content: flex-start; /* Horizontally centers the content */
  position: relative;
  padding: 29px 10px 10px;
}
.calendar-container.calendar-list{
  display: block;
  max-width: 740px;
}
.calendar-container.calendar-list .day.empty{
  display: none;
}
.calendar-container.calendar-list .day:nth-child(7n+2), /* Selects the second column */
.calendar-container.calendar-list .day:nth-child(7n+4),
.calendar-container.calendar-list .day:nth-child(7n+6) { /* Selects the fourth column */
  background-color: transparent; /* Your color for even columns */
}

.calendar-container.calendar-list .day__number{
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 1px solid var(--nonary-color);
  padding-bottom: 6px;
  background: none;
  font-size: 18px;
  font-family: var(--font-body);
  color: var(--secondary-color);
  text-transform: uppercase;
  width: auto;
  height: auto;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.calendar-container.calendar-list .day{
  border-bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  padding: 48px 0px 30px;
}
.calendar-container.calendar-list .list-day-event{
  display: flex;
  gap: 30px;
  flex-direction: row;
  font-size: 18px;
  font-family: var(--font-body);
}
.list-day{
  display: flex;
  flex-direction: column;
  gap: 31px;
}
.calendar-container.calendar-list .day .day-inner{
  width: 100%;
}
.calendar-container.calendar-list .list-day-event__image{
  width: 100px;
}
.calendar-container.calendar-list .list-day-event__image img{
  
}
.calendar-container.calendar-list .list-day-event-details{
  width: calc(100% - 123px);
  border-bottom: 1px solid var(--tertiary-color);
  position: relative;
  color: var(--nonary-color);
  padding-bottom: 10px;
}
.calendar-container.calendar-list .list-day-event-details h3{
  font-family: var(--font-body-two);
  margin-bottom: 10px;
  font-size: 18px;
}
.calendar-container.calendar-list .list-day-event-details a{
  position: relative;
  right: 0;
  bottom: 0px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 11px;
  line-height: 1;
  color: #034828;
}
.calendar-container.calendar-list  .list-day-event-details__details{
  margin-top: 0px;
  position: relative;
  bottom: 0px;
  margin-bottom: 10px;
  max-width: 250px;
}

.day__event{
  padding: 0px 12px 0px;
  margin-bottom: 3px;
  background: #cecece;
  font-size: 12px;
  border-radius: 14px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: calc(100%);
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-height: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  width: -webkit-fill-available;
  white-space: normal;
  cursor: pointer;
}

.calendar-container .day__number{
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  font-size: 16px;
  font-family: var(--font-heading);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-container .day:nth-child(7n+2), /* Selects the second column */
.calendar-container .day:nth-child(7n+4),
.calendar-container .day:nth-child(7n+6) { /* Selects the fourth column */
  background-color: #EFF8E7; /* Your color for even columns */
}
.calendar-title{
  text-align: center;
  font-size: 35px;
  font-family: var(--font-heading);
}
.calendar-header{
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.calendar-container {
  width: 100%;
}

.calendar-container--outer{
  display: flex;
}

.calendar-header__button{
  border: 0;
  background: none;
  cursor: pointer;
  width: 57px;
  height: 57px;
}
.calendar-header__button svg{
  width: 100%;
  height: auto;
}
.calendar-header__button:hover svg path{
  fill: white
}
.calendar-header__title{
  font-size: 30px;
  line-height: 48px;
  font-family: var(--font-body-two);
}

.tooling-right{
  display: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.calendar-view{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 0;
  background: none;
  cursor: pointer;
  color: #006D47;
  font-size: 15px;
}
.calendar-view span{
  white-space: nowrap;
}
.calendar-view:hover svg rect{
  fill: black;
}
.calendar-view:hover{
  color: black;
}
@media (min-width:768px) {
  .category-filter{
    height: 59px;
  }
  .category-filter svg{
    top: -1px;
    right: -2px;
    width: 57px;
  }
  .category-filter-inner{
    padding: 16px 52px 16px 20px;
    border-radius: 30px;
    width: 230px;
  }
  .calendar-header__button{
    width: 70px;
    height: 70px;
  }
  .calendar-container {
    width: calc(100% - 380px);
  }
  .calendar-block{
    padding: 50px 80px;
  }
  .calendar-title{
    font-size: 55px;
  }
  .calendar-header__title{
    font-size: 48px;
    line-height: 72px;
  }
  .tooling-right{
    display: flex;
  }
  .calendar-container.calendar-list .list-day-event{
    gap: 60px;
  }
  .calendar-container.calendar-list .list-day-event-details{
    width: calc(100% - 223px);
    min-height: 150px;
    padding-bottom: 0px;
  }
  .calendar-container.calendar-list .list-day-event__image{
    width: 163px;
  }
  .calendar-container.calendar-list .list-day-event-details a{
    position: absolute;
    bottom: 30px;
    justify-content: center;
    align-items: center;
  }
  .calendar-container.calendar-list .day{
    padding: 66px 0px 49px;
  }
  .calendar-container.calendar-list  .list-day-event-details__details{
    margin-top: 30px;
    position: absolute;
    bottom: 30px;
    max-width: 250px;
  }
  .calendar-container.calendar-list .list-day-event-details h3{
    margin-bottom: 0;
  }
  .calendar-block .tooling{
    padding: 80px 0px 42px;
  }
}
@media (min-width:1250px) {
  .calendar-block{
    padding: 100px 160px;
  }
}
@media (min-width:1700px) {
  .calendar-container .day {
    min-height: 130px;
  }
}

/* Additional styling for days that have an event */


.event-details {
  padding: 40px 50px;
  margin-top: 20px;
  border: 0;
  width: 380px;
  margin-top: 0;
  position: relative;
}

.event-details__date{
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-family: var(--font-body-two);
  color: white;
  background: black;
  border-radius: 0px 0px 20px 0px;
}
.event-details__category{
  font-size: 18px;
  font-family: var(--font-body);
  text-transform: uppercase;
  color: #010101;
  letter-spacing: 1px;
  max-width: 210px;
  margin-bottom: 50px;
}
.event-details__title{
  font-family: var(--font-body-two);
  font-size: 18px;
  margin-bottom: 25px;
  color: var(--quinary-color);
}
.event-details__details{
  font-size: 18px;
  line-height: 24px;
  font-family: var(--font-body);
}
.event-details__image{
  margin-top: 40px;
}

.event-details__cta{
  padding: 18px 65px 18px 20px;
  border-radius: 30px;
  border: 2px solid #010101;
  position: relative;
  z-index: 10;
  display: block;
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 40px;
  text-decoration: none;
  width: min-content;
  white-space: nowrap;
}
.event-details__cta svg {
  position: absolute;
  right: -2px;
  top: -1px;
}
.event-details__cta svg circle{
  fill: #010101;
}


/* POST GRID */

.post-grid-block{
  padding: 25px 0 30px;
}

.post-grid-block-inner{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.post-grid-card{
  cursor: pointer;
  flex: 100%;
  text-decoration: none;
}
.post-grid-card:nth-child(even){
  position: relative;
  top: 0px;
}
.post-grid-card-inner{
  position: relative;
}
.post-grid-card__title{
  font-family: var(--font-body);
  padding: 12px;
}
.post-grid-card__link{
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.post-grid-card-content{
  padding: 50px;
  opacity: 0;
  visibility: hidden;
  background: var(--secondary-color);
  z-index: 1;
  position: absolute;
  line-height: 32px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  transition: all 0.3s ease;
}
.post-grid-card-content.grid-is-3 .post-grid-card__img {
  
}
.post-grid-card-content.grid-is-2 .post-grid-card__img {
  
}
.post-grid-block.grid-is-3 .post-grid-card{

}
.post-grid-block.grid-is-2 .post-grid-card{

}
.post-grid-card-content a{
  text-decoration: none;
}
.post-grid-card__img{
  position: relative;
}
@media (min-width: 768px) {
  .post-grid-card{
    cursor: pointer;
    flex: 1 1 calc(50% - 20px);
    text-decoration: none;
  }
  .post-grid-card:nth-child(even){
    position: relative;
    top: -20px;
  }
  .post-grid-card__img img{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .post-grid-block{
    padding: 25px 0 80px;
  }
  .post-grid-card{
    cursor: pointer;
    flex: 1 1 calc(50% - 20px);
  }
  .post-grid-card:hover .post-grid-card-content{
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .post-grid-card.no-hover:hover .post-grid-card-content{
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  .post-grid-card__title{
    padding: 10px 30px 10px 40px;
  }
  .post-grid-card-content{
    padding: 2vw;
    font-size: 1.2vw;
    line-height: 1.9;
  }
  .post-grid-block .post-grid-card__img{
    height: 48vw;
  }
  .post-grid-block.grid-is-3 .post-grid-card__img {
    width: 100%;
    height: 31vw;
  }
  .post-grid-block.grid-is-2 .post-grid-card__img {
    width: 100%;
    height: 48vw;
  }
}
@media (min-width: 1250px) {
  .post-grid-card{
    cursor: pointer;
    flex: 1 1 calc(25% - 20px);
  }
  .post-grid-block .post-grid-card__img{
    height: 23vw;
  }
  .post-grid-block.grid-is-3 .post-grid-card__img {
    width: 100%;
    height: 31vw;
  }
  .post-grid-block.grid-is-2 .post-grid-card__img {
    width: 100%;
    height: 48vw;
  }
  .post-grid-block .post-grid-card{
    flex: 1 1 calc(25% - 20px);
  }
  .post-grid-block.grid-is-3 .post-grid-card{
    flex: 1 1 calc(33% - 20px);
  }
  .post-grid-block.grid-is-2 .post-grid-card{
    flex: 1 1 calc(50% - 20px);
  }
}
@media (min-width: 1680px) {
  .post-grid-card-content{
    padding: 50px;
    font-size: 16px;
    line-height: 32px;
  }
}

/* Card Grid */
.section > div + div{
  margin-top: -20px;
}
.card-grid-item-block{
  padding: 20px 50px 30px;
  max-width: 1780px;
  margin: 0 auto;
}
.card-grid-item-block-inner{
  display: flex;
  flex-direction: column;
}
.card-grid-item{
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}
.card-grid-item:last-child{
  margin-bottom: 0;;
}
.card-grid-item.one-third{
  width: 100%;
}
.card-grid-item.one-half{
  width: 100%;
  position: relative;
}
.card-grid-item__title{
  position: absolute;
  bottom: 0;
  right: 0;
  color: var(--white);
  background: var(--quinary-color);
  padding: 20px;
  font-size: 20px;
  font-family: var(--font-body);
}
@media (min-width: 768px) {
  .section > div + div{
    margin-top: -60px;
  }
  .card-grid-item-block{
    padding: 80px 80px;
  }
  .card-grid-item.one-third{
    width: 33.33%;
    height: 32vw;
  }
  .card-grid-item.one-half{
    height: 50vw;
    width: 50%;
  }
  .card-grid-item__img{
    position: absolute;
    width: 101%;
    height: 101%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
    object-fit: cover;
  }
  .card-grid-item__img img{
    width: 101%;
    height: 101%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
  .card-grid-item-block-inner{
    flex-direction: row;
  }
  .card-grid-item{
    margin-bottom: 0px;
  }
  .card-grid-item:hover .card-grid-item__img{
    width: 110%;
    height: 110%;
  }
}
@media (min-width: 1250px) {
  .card-grid-item-block{
    padding: 80px 160px;
  }
  .card-grid-item__title{
    font-size: 28px;
    padding: 20px;
  }
}
@media (min-width: 1600px) {
  .card-grid-item__title{
    padding: 30px;
    font-size: 35px;
  }
}
@media (min-width: 1780px) {
  .card-grid-item.one-third{
    height: 569px;
  }
  .card-grid-item.one-half{
    height: 885px;
  }
}

/* Map Block */
.map-block{
  max-width: 1780px; 
  margin: 0 auto;
}
.map-block .inner{
  padding: 30px 30px 50px;
}
.map-block .address{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.map-block .address svg path{
   fill: white;
}
.map-block .content{
  padding: 50px 30px;
  background: var(--secondary-color);
  color: white;
}
.map-block__title{
  font-family: var(--font-body);
  margin-bottom: 40px; 
}
.map-block .media-items{
  height: 100%;
}
.map-block .media-items *{
  height: 100%;
}
.map-block .media-items img{
  object-fit: cover;
}
.map-block .tabs{
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  margin: 40px 0px 30px;
}
.map-block .tab-content{
  line-height: 32px;
}
.map-block .tab-button{
  color: var(--senary-color);
  background: none;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}
.map-block .tab-button.active svg{
  background: none;
}
.map-block .tab-button.active svg path{
  fill: var(--senary-color);
}
.map-block .tab-button svg{
  border-radius: 50%;
  border: 2px solid var(--senary-color);
  background: var(--senary-color);
  margin-bottom: 10px;
}
.map-block .tab-button svg rect{
  fill: none;
}
@media (max-width: 768px) {
  .map-block .address svg{
    width: 15px;
    height: auto;
  }
  .map-block .tab-button svg{
    width: 50px;
    height: auto;
    margin-bottom: 5px;
  }
  .map-block .tab-button .option-title{
    display: none;
  }
}
@media (min-width: 768px) {
  .map-block .inner{
    padding: 50px 80px;
  }
  .map-block .tabs{
    margin: 50px 0px;
    gap: 15px;
  }
  .map-block .content{
    padding: 70px 50px;
  }
}
@media (min-width: 1250px) {
  .map-block .inner{
    padding: 50px 160px;
  }
  .map-block .content{
    padding: 100px 50px;
  }
}


/* Simple Text Content */

.simple-text-content-block{
  max-width: 1525px;
  margin: 0 auto;
}

.simple-text-content-block .inner{
  padding: 30px 50px;
}

.simple-text-content-block .content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}
.simple-text-content-block ul li{
  margin-bottom: 20px;
}

.simple-text-content-block .content__header{
  margin-bottom: 20px;
  font-family: var(--font-body);
  font-size: 24px;
}

.simple-text-content-block .content__body{

}

.simple-text-content-block .disclaimer{
  max-width: 780px;
  margin: 20px auto 0;
  text-align: center;
  line-height: 32px;
}

@media (min-width: 768px) {

  .simple-text-content-block .content__header{
    font-size: 35px;
  }
  
  .simple-text-content-block .inner{
    padding: 50px 80px;
  }
  .simple-text-content-block .content{
    padding: 0 50px;
  }
}

@media (min-width: 1250px) {
  .simple-text-content-block .inner{
    padding: 80px 160px;
  }
}

/* Two Column Action */

.two-column-action-block{
  padding: 50px 30px;
  max-width: 1700px;
  margin: 0 auto;
}
.two-column-action-block-inner{
  display: flex;
  flex-direction: column;
}
.two-column-action-block-inner .content{
  padding: 50px 30px; 
  background: var(--primary-color);
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.two-column-action-block.neutral .content{
  background: none;
  color: #010101;
}

.two-column-action-block.neutral .anchor-image-top-left{
  display: none;
}
.two-column-action-block.neutral .anchor-image-bottom-right{
  display: none;
}

.two-column-action-block .content .anchor-image-top-left{
  position: absolute;
  top: 0;
  left: 0;
  width: 35%;
}
.two-column-action-block .content .anchor-image-bottom-right{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 11%;
}

.two-column-action-block.neutral .two-column-action__body{
  color: #01462B;
}
.two-column-action-block.neutral .content{
  padding: 30px 0px;
}
.two-column-action-block .newsletter-wrapper{
  padding: 30px 20px;
}
.two-column-action__body + .buttons-wrapper, .two-column-action__small_body + .buttons-wrapper{
  margin-top: 40px;
}

.two-column-action__body + .two-column-action__small_body{
  margin-top: 40px;
}

.two-column-action-block.neutral .two-column-action__header{
  color: #01462B;
}
.two-column-action-block.neutral .two-column-action__header strong{
  color: var(--tertiary-color)
}

.two-column-action__small_body{
  line-height: 32px;
}

.two-column-action__header{
  font-size: 35px;
  white-space: pre-wrap;
}

.two-column-action__body{
  margin-top: 20px;
  font-size: 24px;
  line-height: 32px;
}

.two-column-action-block .buttons-wrapper .btn{
  background: var(--nonary-color);
  border: 2px solid var(--nonary-color);
}
.two-column-action-block .buttons-wrapper .btn:hover{
  background: var(--primary-color);
}

.two-column-action-block .buttons-wrapper .btn.secondary{
  border: 2px solid var(--senary-color);
  background-color: transparent;
  color: var(--white);
}
.two-column-action-block .buttons-wrapper .btn.secondary:hover{
  border: 2px solid var(--septenary-color);
  background: var(--septenary-color);
}

.two-column-action-block-inner .column{
  flex-basis: 50%;
}
.two-column-action-block .media-items--outer{
  height: 100%;
}
.two-column-action-block .media-items--outer *{
  height: 100%;
}
.two-column-action-block .media-items img{
  height: 100%;
  object-fit: cover;
}
.two-column-action-block .buttons-wrapper{
  margin-top: 20px;
  gap: 20px;
  flex-wrap: wrap;;
}
.two-column-action-block .buttons-wrapper .btn{
margin: 0 !important;
}
.two-column-action-block .form-title{
  display: none;
}
.newsletter-wrapper, .form-wrapper form{
  padding: 50px 0;
}
.checkbox{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 10px;
}
fieldset.checkboxes{
  margin-top: 22px;
}
fieldset.checkboxes > label{
  padding: 0 0 15px !important;
}
fieldset{
  border: 0;
  background: whitesmoke;
}
fieldset .error-message{
  bottom: -20px !important;
  left: 0px !important;

}
.checkbox label{
  padding: 0;
}
.checkbox input[type="checkbox"]{
  width: 26px;
  height: 28px;
}
.form-input-wrapper, .form-wrapper form .form-field{
  margin-bottom: 15px;
  position: relative;
}
.form-input-wrapper span, .form-wrapper form label{
  display: block;
  padding: 0px 16px 10px 16px;
}
.form-input-wrapper input, .form-wrapper form input{
  width: 100%;
  border: none;
  padding: 16px;
  background: #F5F5F5;
  font-size: 18px;
}
.form-wrapper form .form-field .date{
  display: flex;
  gap: 10px;
}
.form-wrapper form .form-field .gfield_date_dropdown{
  width: 33.33%;
}
.form-input-wrapper input::placeholder, .form-wrapper form input::placeholder{
  color: #C4C4C4
}
.form-field.error .form-select{
  border: 2px solid red !important;
}
.error-message{
  position: absolute;
  bottom: 60px;
  right: 6px;
  font-size: 10px;
  color: red;
}
.form-select + .error-message{
  bottom: 64px;
}
.form-select > div > div + div > div svg path{
 fill: black;
}
.form-select > div > div + div > span{
  display: none;
}
.form-select > div + div > div{
  background: #F5F5F5;
}
.form-select > div{
  background: #F5F5F5;
  border: 0;
  height: 53px;
  border-radius: 0;
}
.gfield_date_dropdown > div > div > div + div > span {
  display: none;
}
.gfield_date_dropdown > div > div > div svg path {
  fill: black;
}
.gfield_date_dropdown > div > div{
  background: #F5F5F5;
  border: 0;
  height: 53px;
  border-radius: 0;
}
.gfield_date_dropdown > div > span + div + div > div{
  background: #F5F5F5;
}
.form-title{
  margin-bottom: 30px;
}
[aria-invalid="true"]{
  border: 2px solid red !important;
}
.newsletter-wrapper button{
  margin-top: 10px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .two-column-action-block .buttons-wrapper{
    margin-top: 35px;
  }
  .two-column-action__body{
    font-size: 35px;
    line-height: 45px;
  }
  .two-column-action-block.neutral .content{
    padding: 30px 80px;
  }
  .two-column-action-block .newsletter-wrapper{
    padding: 30px 80px;
  }
  .two-column-action-block-inner .content{
    padding: 50px 80px; 
  }
  .two-column-action-block-inner{
    flex-direction: row;
  }
  .two-column-action-block{
    padding: 70px 80px;
  }
  .two-column-action__header{
    font-size: 45px;
  }
  .newsletter-wrapper, .form-wrapper form{
    padding: 35px 80px;
  }
}
@media (min-width: 1250px) {
  .two-column-action-block.neutral .content{
    padding: 30px 85px;
  }
  .two-column-action-block .newsletter-wrapper{
    padding: 30px 120px;
  }
  .two-column-action-block-inner .content{
    padding: 65px 80px; 
  }
  .two-column-action-block{
    padding: 90px 100px;
  }
  .two-column-action__header{
    font-size: 45px;
  }
  .newsletter-wrapper, .form-wrapper form{
    padding: 50px 100px;
  }
}
@media (min-width: 1640px) {
  .two-column-action__header{
    font-size: 55px;
  }
  .newsletter-wrapper, .form-wrapper form{
    padding: 50px 160px;
  }
  .two-column-action-block-inner .content{
    padding: 100px 120px; 
  }
}


/* Simple Wysiwyg */
.simple-wysiwyg-block{
  max-width: 1780px;
  margin: 0 auto;
  padding: 20px 50px;
}
.simple-wysiwyg-block .heading{
  margin-bottom: 40px;
}
.simple-wysiwyg-block .body{
  max-width: 768px;
  line-height: 32px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .simple-wysiwyg-block{
    padding: 50px 80px;
  }
}
@media (min-width: 1250px) {
  .simple-wysiwyg-block{
    padding: 100px 160px;
  }
}

/* Post Content */

.post-content-block{
  margin: 0 auto;
  max-width: 1780px;
  padding: 0px 50px 35px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
}
.post-content-inner{
  padding-top: 50px;
  width: 100%;
}
.post-content__header{
  margin-bottom: 50px;
}
.post-content__header strong{
  color: var(--tertiary-color);
}
.post-content-inner hr{
  margin-bottom: 30px;
  border-color: var(--tertiary-color);
}
.post-content-inner p, .post-content-inner ul, .post-content-inner ol{
  margin-bottom: 40px;
  color: #012302;
}
.post-content-inner ol li, .post-content-inner ul li{
  margin-bottom: 14px;
}
.post-content-inner .large{
  font-size: 35px;
  color: #01462B;
}
.post-content__body{
  margin-bottom:30px;
}
.post-content-inner .accordion{
  white-space: normal;
}
.post-content-inner * + .accordion{
  margin-top: -15px;
}
.post-content-inner .accordion + .accordion{
  margin-top: 0;
}
.post-content-inner .buttons-wrapper{
  margin-bottom: 40px;
}
.post-content-inner{
  line-height: 32px;
}
.post-content-banner{
  padding: 50px 30px;
  width: 100%;
  background: var(--primary-color);
}
.post-content-banner .upcoming-events{
  margin-bottom:30px;
}
.post-content-banner .upcoming-events h4{
  text-align: center;
  font-family: var(--font-body-two);
  margin: 0px 0px 20px;
  color: white;
}
.post-content-banner .upcoming-events .event-item{
  text-align: center;
  margin: 30px 0px 20px;
  color: white;
}
.post-content-inner .slick-slider{
  margin-bottom: 50px;
}
.post-content-inner .slick-slide img{
  height: 68vw;
  padding-right: 10px;
  object-fit: cover;
}

.slick-prev{
 left: 20px;
}
.slick-next{
  right:38px;
}
.slick-prev, .slick-next{
  z-index: 10;
}
.slick-prev:before, .slick-next:before{
  font-size: 40px;
}
.slick-dots li {
  width: 14px;
  height: 14px;
  margin: 0 5px;
}
.slick-dots li button:before{
  font-size: 25px;
  content: ' ';
  width: 14px;
  height: 14px;
  border-radius: 50%;
  opacity: 1;
  border: 2px solid white;
}
.slick-dots li.slick-active button:before{
  background: white;
  opacity: 1;
}

.registration-button{
  text-align: center;
  margin-bottom: 30px;
}
.registration-button .btn{
  background: var(--nonary-color);
  border: 2px solid var(--nonary-color);
}
.registration-button .btn:hover{
  background: var(--primary-color);
}
.post-content-banner > .social-sharing{
  border-top: 0px !important;
}
.social-sharing{
  text-align: center;
  color: white;
  border-top: 1px solid white;
}
.partner-logos-wrapper{
  text-align: center;
  color: white;
  border-top: 1px solid white;
}
.social-sharing .icons{
  display: flex;
  gap: 10px;
  justify-content:center;
  align-items: flex-start;
  margin-bottom: 20px;
}
.social-sharing .icons button{
  background: none;
  border: 0;
  cursor: pointer;
}
.partner-logos-wrapper__header, .social-sharing__header{
  font-family: var(--font-body-two);
  margin: 30px 0px 20px;
}
.partner-logos{
  display: flex;
  gap: 20px;
}
.partner-logos__image{
  flex-basis: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  background: #ececec;
}
.partner-logos__image img{
  padding: 20px;
}
.accordion-title{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: 0;
  border: 0;
  text-align: left;
  cursor: pointer;
}
.accordion-title svg{
  position: relative;
  top: 15px;
  min-width: 27px;
}
.accordion-content{
  padding-left: 50px;
}
.accordion-content p{
  margin-bottom: 40px;
}
.accordion-content p:last-child{
  margin-bottom: 10px;
}
.accordion-item{
  max-width: 815px;
}
.accordion-title span{
  font-family: var(--font-body-two);
  padding: 15px 20px 20px;
  font-size: 18px;
  color: #012302;
}
@media (min-width: 768px) {
  .post-content-inner .slick-slide img{
    height: 30vw;
  }
  .post-content-inner .slick-slider{
    margin-bottom: 100px;
  }
  .post-content-inner{
    padding-top: 100px;
    width: calc(100% - 450px);
  }
  .post-content-banner{
    padding: 70px 30px;
    width: 375px;
  }
  .post-content-block{
    flex-direction: row;
    gap: 70px;
    padding: 0px 80px 50px;
  }
  
  .slick-dots li button:before{
    font-size: 25px;
  }
  .slick-dots li {
    width: 20px;
    height: 20px;
    margin: 0 9px;
  }
  .slick-prev:before, .slick-next:before{
    font-size: 60px;
  }  
  .slick-next{
    right: 60px;
  }
}
@media (min-width: 1250px) {
  .post-content-block{
    padding: 0px 160px 100px;
  }
}


.featured-image-block *{
  height: 68vw;
  object-fit: cover;
}
@media (min-width: 768px) {
  .featured-image-block *{
    height: auto;
  }
}

/* Hamnburger */
/* Mobile menu toggle */
.hamburger-icon:hover .line, .hamburger-icon:focus .line{
  background-color: var(--nonary-color);
}
.hamburger-icon {
  display: inline-block;
  position: fixed;
  display: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  right: 23px;
  top: 34px;
}

.hamburger-icon .line {
  width: 82%;
  height: 4px;
  background-color: #009447;  
  position: absolute;
  transition: transform 0.3s ease, top 0.3s ease, opacity 0.3s ease;
}

.hamburger-icon .line:nth-child(1) {
  top: 0;
}

.hamburger-icon .line:nth-child(2) {
  top: 11px;
}

.hamburger-icon .line:nth-child(3) {
  top: 22px;
}

header.active .hamburger-icon .line:nth-child(1) {
  transform: rotate(-45deg);
  top: 10px;
}
header.active .hamburger-icon .line:nth-child(2){
  transform: rotate(45deg);
  top: 10px;
}
header.active .hamburger-icon .line:nth-child(3){
  transform: rotate(-45deg);
  top: 10px;
}
header .utility {
  display: flex;
}

header .utility.active {
  display: flex;
}

@media (max-width: 1250px) {
  header .header-logo-container{
    position: fixed;
    transform: none;
    top: 21px;
    left: 30px;
    width: max-content;
  }
  header .menu-items{
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .menu-items li a{
    font-size: 24px;
  }
  .menu-items{
    gap: 20px;
  }
  .hamburger-icon {
    display: block;
  }
  header.active{
    height: 100%;
  }
  header .utility__icon{
    position: fixed;
    top: 22px;
    right: 85px;
  }
  header .utility {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 60px; /* Adjust based on your header height */
    left: 0;
    right: 0;
    transform: none;
    background: transparent;
    padding: 20px;
    box-shadow: none;
    z-index: 1000;
  }
  
  header .utility.active {
    display: flex;
    height: 100%;
  }
  
  header .utility__icon {
    margin-top: 10px;
  }
}

.accordion-block{
  max-width: 1780px; 
  margin: 0 auto;
}
.accordion-block .inner{
  padding: 30px 20px 50px;
}

@media (min-width: 768px) {
  .accordion-block .inner{
    padding: 50px 80px;
  }
}
@media (min-width: 1250px) {
  .accordion-block .inner{
    padding: 100px 160px;
  }
}

/* SEARCH */

/* Container styling */
.search-block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 20px 400px;
  background-color: #f5f5f5;
}

.search-block .inner {
  width: 100%;
  max-width: 700px;
}

/* Form styling */
.search-block form {
  display: flex;
  background-color: var(--white);
  padding: 40px 70px;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

/* Input styling */
.search-block input[type="text"] {
  flex: 1;
  padding: 15px;
  border: 2px solid var(--primary-color);
  border-radius: 30px 0 0 30px;
  font-size: 16px;
}

/* Button styling */
.search-block button {
  padding: 16px 30px 16px 20px;
  border: none;
  background-color: var(--primary-color);
  color: white;
  font-size: 16px;
  border-radius: 0 30px 30px 0;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  transition: background-color 0.3s;
}

.search-block button:hover {
  background-color: var(--nonary-color);
}

/* Responsive styling */
@media (max-width: 1250px) {
  .search-block {
    padding: 100px 20px;
  }
  .search-block .inner {
      width: 90%;
  }

  .search-block input[type="text"] {
      font-size: 14px;
  }

  .search-block button {
      font-size: 14px;
      padding: 16px 16px;
  }
}


/* Search Results container */
.search-results {
  width: 100%;
  max-width: 700px;
  background-color: var(--white);
  padding: 20px 30px;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

/* Heading */
.search-results h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

/* List styles */
.search-results ul {
  list-style-type: none;
  padding: 0;
}

.search-results ul li {
  margin-bottom: 10px;
}

/* Link styles */
.search-results a {
  text-decoration: none;
  color: var(--primary-color);
  transition: color 0.3s;
}

.search-results a:hover {
  color: var(--primary-color);
}

/* Image and Text Block */

.image-and-text-block{
  max-width: 1780px;
  margin: 0 auto;
  padding: 50px 30px;
}
.image-and-text-block .anchor-image-bottom{
  bottom: 0;
  position: absolute;
  height: auto;
}
.image-and-text-block .grid-wrapper{
  gap: 60px;
  align-items: center;
}
.image-and-text-block .grid-wrapper .grid.left{
  width: calc(100%);
}
.image-and-text-block .grid-wrapper .grid.right{
  width: 100%;
}
.image-and-text-block .slider-wrapper{
  width: 100%;
  position: relative
}
.image-and-text-block .slider-wrapper .slick-dots{
  bottom: 50px;
}
.image-and-text-block .inner{
  position: relative;
  width: 100%;
}
.image-and-text-block img{
  width: 100%;
  height: 65vw;
  object-fit: cover;
}
.image-and-text-block .content{
  color: #012302;
}
.image-and-text-block .content .heading strong{
  color: #049546;
}
.image-and-text-block .content .heading{
  margin-bottom: 30px;
}
.image-and-text-block .content .body-content.large{
  font-size: 26px;
  margin-bottom: 20px;
  line-height: 1.2;
}
.image-and-text-block .content .body-content{
  line-height: 32px;
}
.image-and-text-block .content .buttons-wrapper{
  margin-top: 30px;
}
.image-and-text-block .content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

@media (min-width: 768px) {
  .image-and-text-block .content .body-content.large{
    font-size: 35px;
  }
  .image-and-text-block .slider-wrapper .slick-dots{
    bottom: 90px;
  }
}
@media (min-width: 1250px) {
  .image-and-text-block{
    padding: 100px 160px;
  }
  .image-and-text-block .grid-wrapper{
    gap: 60px;
  }
  .image-and-text-block .grid-wrapper .grid.left{
    width: 500px;
  }
  .image-and-text-block .grid-wrapper .grid.right{
    width: calc(100% - 500px);
  }
  .image-and-text-block img{
    height: 500px;
  }
}
@media (min-width: 1600px) {
  .image-and-text-block .grid-wrapper .grid.left{
    width: 650px;
  }
  .image-and-text-block .grid-wrapper .grid.right{
    width: calc(100% - 650px);
  }
  .image-and-text-block img{
    height: 650px;
  }
}

/* LOADING ANIMATIONS AND SKELETONS */
.loading{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: var(--nonary-color);
}
.loading-inner{
  position: fixed;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
}

/* HTML: <div class="loader"></div> */
.loader {
  height: 80px;
  aspect-ratio: 1;
  display: grid;
}
.loader:before,
.loader:after {
  content: "";
  --c:no-repeat linear-gradient(#fff 0 0);
  background: var(--c), var(--c);
  background-size: 25% 50%;
  animation: l4 1.5s infinite linear;
}
.loader:after {
  transform: scale(-1);
}
.lazyload{
  opacity: 0;
  -webkit-transition: all 2s ease; 
  -moz-transition: all 2s ease; 
  -ms-transition: all 2s ease; 
  -o-transition: all 2s ease; 
}
@keyframes l4 {
  0%,
  10%  {background-position:33.4% 100%,66.6% 100%}
  40%  {background-position:33.4% 0,100% 100%}
  70%  {background-position:0 100%,66.6% 0}
  100% {background-position:33.4% 100%,66.6% 100%}
} 

.animated-block.hero + .animated-block{
  padding-top: 60px !important;
}
.animated-block .hero + .animated-block .slim-header-and-content, .animated-block.hero + .animated-block.calendar{
  padding-top: 85px !important;
}
.animated-block div + .animated-block.two-column-action-block{
  margin-top: 0px !important;
}
.animated-block.mapblock + .animated-block{
  margin-top: 0px !important;
}

.animated-block + .animated-block.accordion .accordion-block .inner{
  padding-top: 20px !important;
}

.animated-block.hero + .animated-block.accordion{
  padding-top: 95px !important;
}
.animated-block.hero.two_column + .animated-block.slim-header-and-content{
  padding-top: 20px !important;
}

@media (min-width:768px) {
  .animated-block.hero + .animated-block.accordion{
    padding-top: 150px !important;
  }
  .animated-block.hero + .animated-block.featured-image{
    padding-top: 0px !important;
  }
  .animated-block.hero + .animated-block{
    padding-top: 120px !important;
  }
  .animated-block.hero.two-column-action + .animated-block.slim-header-and-content{
    padding-top: 80px !important;
  }
}
@media (min-width:1440px) {
  .animated-block + .animated-block.accordion .accordion-block .inner{
    padding-top: 0px !important;
  }
  .animated-block.hero + .animated-block.accordion{
    padding-top: 227px !important;
  }
}